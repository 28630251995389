import { useWindowSize } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import React from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from ".";
import SidebarBranded from "../SidebarBranded";
import AutomaticallySetSchool from "./AutomaticallySetSchool";
import Loading from "./Loading";
import MagicLinkListener from "./MagicLinkListener";

const View = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { pageLoading, allScreens, currentPage, showSidebar } =
    useContainer(SignUpFlowContext);

  const { width } = useWindowSize();

  return (
    <AutomaticallySetSchool>
      <MagicLinkListener>
        <div className="flex flex-col h-full w-full md:flex-row kanpla-primary-bg">
          {width >= 768 &&
            showSidebar &&
            !isFromAnonymousFlow &&
            !isFromAPrivateModule && (
              <SidebarBranded displayStoreButtons={false} />
            )}
          {!pageLoading ? (
            <div className="h-full w-full">
              {allScreens[currentPage]?.content}
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </MagicLinkListener>
    </AutomaticallySetSchool>
  );
};

export default View;
