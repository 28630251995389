import { db, Timestamp } from "@kanpla/system";
import { EventTracking, Module } from "@kanpla/types";
import { IntroModal } from "@kanpla/ui";
// import * as Sentry from "@sentry/nextjs";
import * as Sentry from "@sentry/nextjs";
import { isEmpty } from "lodash";
import { default as React, Dispatch, SetStateAction, useEffect } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hideSkipQueue?: boolean;
  module?: Module;
  automaticallyOpen?: boolean;
}

const KanplaGoIntro = ({
  open,
  setOpen,
  module,
  hideSkipQueue = false,
  automaticallyOpen,
}: Props) => {
  const { t } = useTranslation(["translation", "modals"]);
  const { childId, userId, schoolId } = useContainer(AppContext);

  const allowAutomaticOpening =
    automaticallyOpen && childId && module?.id && userId;

  const [
    event = [{ title: "To start with" }],
    eventLoading = true,
    eventError,
  ] = useCollectionDataOnce<EventTracking>(
    allowAutomaticOpening
      ? db
          .collection("eventTracking")
          .where("type", "==", "kanplaGo")
          .where("componentId", "==", module.id)
          .where("childId", "==", childId)
          .where("schoolId", "==", schoolId)
          .where("userId", "==", userId)
      : null
  );

  useEffect(() => {
    if (eventError) console.error(eventError);
  }, [eventError]);

  const registerEvent = async () => {
    try {
      await db.collection("eventTracking").add({
        type: "kanplaGo",
        componentId: module.id,
        childId,
        userId,
        schoolId,
        created_at: Timestamp.now().seconds,
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (!automaticallyOpen) return;
    console.log(
      "🚀 ~ file: KanplaGoIntro.tsx ~ line 69 ~ useEffect ~ automaticallyOpen",
      automaticallyOpen
    );
    if (!allowAutomaticOpening) return;
    // automaticallyOpen && childId && module?.id && userId;

    if (eventLoading) return;
    console.log(
      "🚀 ~ file: KanplaGoIntro.tsx ~ line 68 ~ useEffect ~ eventLoading",
      eventLoading
    );
    console.log("event", event);

    if (!isEmpty(event)) return;
    console.log(
      "🚀 ~ file: KanplaGoIntro.tsx ~ line 71 ~ useEffect ~ event",
      event
    );

    setOpen(true);
    registerEvent();
  }, [eventLoading, automaticallyOpen, allowAutomaticOpening]);

  const data = Object.entries(module?.text || {}).reduce(
    (acc, [key, value], i) => {
      const split = key.split("/");
      if (split[0] !== "kanplaGoModal") return acc;
      if (split[1] === "title") return acc;
      if (split[1] === "subtitle") return acc;

      const index = split[1].replace("step", "");
      return { ...acc, [index]: { ...acc[index], [split[2]]: value } };
    },
    {}
  );

  // Create default data
  const defaultData = {
    1: {
      title: "Grab 🙌",
      description: t("translation:kanpla-go-intro-component.1.description"),
    },
    2: {
      title: "Buy 💳",
      description: t("translation:kanpla-go-intro-component.2.description"),
    },
    3: {
      title: t("translation:kanpla-go-intro-component.3.title"),
      description: t("translation:kanpla-go-intro-component.3.description"),
    },
    4: {
      title: "Go ✅",
      description: t("translation:kanpla-go-intro-component.4.description"),
    },
  };

  if (hideSkipQueue) delete defaultData[3];
  if (Object.values(data).length === 3) delete defaultData[4];

  const finalData = { ...defaultData, ...(data || {}) };

  return (
    <IntroModal
      title={
        module?.text?.["kanplaGoModal/title"] ||
        t("translation:that-is-how-it-works")
      }
      subtitle={module?.text?.["kanplaGoModal/subtitle"]}
      data={Object.values(finalData)}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default KanplaGoIntro;
