import { auth } from "@kanpla/system";
import { Providers } from "@kanpla/types";
import { providerSetup } from "apps/frontend/components/signup-flow/screens/Provider";
import {
  getAdditionalUserInfo,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import callAfterClass from "./callAfterClass";
import getProviderClass from "./getProviderClass";

export const processProviders = async (
  providerName: string,
  providers: Array<Providers>,
  email: string
) => {
  if (!providerName) throw new Error("Autentikation-udbyderen er ikke opsat");

  const providerId = providerSetup[providerName]?.id as string;
  if (!providerId) throw new Error("Autentikation-udbyderen er ikke opsat");

  const provider = providers.find((p) => p.name === providerName);

  const providerClass = getProviderClass(providerId, {
    email,
    tenantId: provider?.tenantId,
  });

  await signInWithRedirect(auth, providerClass);
};
