import { AnonymousPropsFlow } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";
import { useLocalstorageState } from "rooks";
import SignupCarouselWrapper from "../../introduction/SignupCarouselWrapper";

const Completed = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const router = useRouter();
  const { modules } = useContainer(AppContext);
  const { signupScreens, schoolId, child } = useContainer(SignUpFlowContext);

  const [shouldShowSignupCarousel, _] = useLocalstorageState(
    "show-signup-carousel",
    true
  );

  const showCarousel = shouldShowSignupCarousel && !isEmpty(signupScreens);

  // Hide carousel if modules include kanplaGo
  const hasKanplaGo = modules.some((m) => m?.plugins?.kanplaGo?.active);

  const continueLink = hasKanplaGo
    ? "/app"
    : "/app?showIntroductionCarousel=true";

  // Go to the app immediately
  useEffect(() => {
    if (showCarousel) return;
    router.push(continueLink);
  }, [hasKanplaGo, showCarousel]);

  return showCarousel ? (
    <SignupCarouselWrapper
      schoolId={schoolId}
      childId={child.id}
      signupScreens={signupScreens}
      pushToUrl={continueLink}
    />
  ) : (
    <PageWrapper
      hideButton={true}
      title={t("signup-flow:you-are-ready-now")}
      buttonTitle=""
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="w-full h-full flex justify-center items-center text-text-primary">
        <Spinner size="medium" useCurrentColor />
      </div>
    </PageWrapper>
  );
};

export default Completed;
