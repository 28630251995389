import { ModalWrapper, SignupCarousel } from "@kanpla/ui";
import { isEmpty } from "lodash";
import router from "next/router";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { useLocalstorageState } from "rooks";
import { callFunction } from "@kanpla/system";
import { PopupConstructor } from "@kanpla/types";

interface Props {
  signupScreens: PopupConstructor.Fullscreen[];
  childId: string;
  schoolId: string;
  pushToUrl: string;
}

const SignupCarouselWrapper = (props: Props) => {
  const { pushToUrl, signupScreens, childId, schoolId } = props;
  const { supplier, mobile, user, partnerId } = useContainer(AppContext);

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const isRenderedInWebView =
    typeof window !== undefined && (window?.["isRenderedInWebView"] as boolean);

  const isDesktopView = !mobile;

  const [shouldShowSignupCarousel, setShouldShowSignupCarousel] =
    useLocalstorageState("show-signup-carousel", true);

  const [notificationPermissionsReceived, setNotificationPermissionsReceived] =
    useState(false);
  const [trackingPermissionsReceived, setTrackingPermissionsReceived] =
    useState(false);

  const handleEventFromWebView = async (event) => {
    const { data } = event;
    if (typeof data !== "string") return;
    const parsedData = JSON.parse(data);

    if (parsedData?.answeredToNotificationsPermissions) {
      setNotificationPermissionsReceived(true);
      const hasAllowedNotifications =
        parsedData?.hasAllowedNotifications as boolean;

      await callFunction("popups-registerNotificationEvent", {
        childId,
        schoolId,
        partnerId,
        hasAllowedNotifications,
      });
      return;
    }

    if (parsedData?.answeredToTrackingPermissions) {
      setTrackingPermissionsReceived(true);
      return;
    }
  };

  const handleOnLastCustomScreen = async () => {
    try {
      setLoading(true);

      await callFunction("popups-registerPopupEvents", {
        childId,
        schoolId,
        popupIds: signupScreens.map((s) => s.id),
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isRenderedInWebView) return;
    window.addEventListener("message", handleEventFromWebView);
    return () => window.removeEventListener("message", handleEventFromWebView);
  }, [isRenderedInWebView]);

  if (isEmpty(signupScreens)) return null;

  if (isDesktopView) {
    return (
      <ModalWrapper
        open={open}
        setOpen={setOpen}
        closableOutside={false}
        containerClassName="md:py-16"
        innerClassName="md:rounded-lg md:shadow-xl relative w-full md:max-w-2xl md:h-full h-auto mx-auto overflow-hidden"
      >
        <SignupCarousel
          screens={signupScreens}
          supplier={supplier}
          onFinish={() => {
            setShouldShowSignupCarousel(false);
            router.push(pushToUrl);
          }}
          onLastCustomScreen={handleOnLastCustomScreen}
          lastButtonLoading={loading}
          hideSystemScreens
          desktopView
        />
      </ModalWrapper>
    );
  }

  return (
    <div className="w-full h-full absolute top-0">
      <SignupCarousel
        screens={signupScreens}
        supplier={supplier}
        onFinish={() => {
          setShouldShowSignupCarousel(false);
          router.push(pushToUrl);
        }}
        onAllowNotifications={() => {
          window?.["ReactNativeWebView"].postMessage(
            JSON.stringify({ askForNotifications: true })
          );
        }}
        notificationPermissionsReceived={notificationPermissionsReceived}
        onAllowTracking={() => {
          window?.["ReactNativeWebView"].postMessage(
            JSON.stringify({ askForTracking: true })
          );
        }}
        trackingPermissionsReceived={trackingPermissionsReceived}
        onLastCustomScreen={handleOnLastCustomScreen}
        lastButtonLoading={loading}
        hideSystemScreens={!isRenderedInWebView}
        showSystemScreensOnly={
          isRenderedInWebView &&
          shouldShowSignupCarousel &&
          user?.hasSeenSignupCarousel
        }
      />
    </div>
  );
};

export default SignupCarouselWrapper;
