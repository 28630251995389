import { FieldValue } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { FormInput } from "@kanpla/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";

const AccessCode = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { school, schoolModules, child } = useContainer(SignUpFlowContext);
  const [newCode, setNewCode] = useState("");

  const allAvailableCodes = schoolModules
    .filter((module) => module.schoolIds.includes(school?.id || ""))
    .map((module) => [
      ...(module.config?.codes || []),
      ...(module.config?.bulkCodes || []),
      ...(school.modules?.[module.id]?.codes || []),
      ...(school.modules?.[module.id]?.bulkCodes || []),
    ])
    .flat();

  const submit = async () => {
    const codeIsValid = allAvailableCodes.includes(newCode);

    if (!codeIsValid) {
      throw new Error(t("translation:message.error.invalid-special-password"));
    }

    await child.ref.update({
      codes: FieldValue.arrayUnion(newCode),
    });
  };

  return (
    <PageWrapper
      title={t("translation:registration-code")}
      subtitle={t("signup-flow:enter-code")}
      buttonTitle={t("signup-flow:add-registration-code")}
      topIllustration="/images/signup-flow/access-code.svg"
      onButtonSubmit={submit}
      skipButton={true}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <FormInput
        label={t("translation:form.labels.special-password")}
        note={t("translation:form.notes.grandet-access")}
        value={newCode}
        setValue={(v) => setNewCode(v)}
      />
    </PageWrapper>
  );
};

export default AccessCode;
