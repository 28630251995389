import { useTranslation } from "react-i18next";
import Link from "next/link";
import React from "react";

const LoginLink = () => {
  const { t } = useTranslation(["signup-flow"]);
  return (
    <div className="mt-3 text-center pt-4">
      {t("signup-flow:have-already-account")}{" "}
      <Link href="./login">
        <a className="font-semibold">{t("signup-flow:sign-in")}</a>
      </Link>
    </div>
  );
};

export default LoginLink;
