import { NewLogo, Image } from "@kanpla/ui";
import { detect } from "detect-browser";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { BrowserView } from "react-device-detect";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  displayStoreButtons?: boolean;
}

const SidebarBranded = (props: Props) => {
  const { displayStoreButtons = false } = props;
  const { customBranding, customBrandingLoading } = useContainer(AppContext);
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState<string>(i18n.language);

  const browser = detect();

  const appStoreId = customBranding?.appstoreId || "id1500582659";
  const googlePlayId =
    customBranding?.googlePlayId || customBranding?.url || "parents";

  const hideGooglePlay =
    browser?.os === "iOS" ||
    browser.name === "safari" ||
    browser.os === "Mac OS";

  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  return (
    <div
      id="navbar"
      className="h-auto md:h-full w-full md:max-w-md md:flex md:flex-col md:items-start"
      style={{ backgroundColor: "var(--palette-primary-main)" }}
    >
      <div className="relative flex w-full h-full md:justify-center items-center  p-8 md:p-0 md:min-h-screen">
        <div
          className="absolute w-full h-full z-30"
          style={{
            background:
              "linear-gradient(150deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.8) 100%)",
          }}
        />
        <div
          className="absolute opacity-30 w-full h-full z-20"
          style={{ backgroundColor: "var(--palette-primary-main)" }}
        />
        {!customBrandingLoading && (
          <Image
            className={`w-full h-full object-cover select-none hidden md:block z-10  md:min-h-screen ${
              customBrandingLoading ? "opacity-0" : ""
            }`}
            src={
              customBranding?.custom?.images?.landingBackground ||
              "/images/cooking-hero-join.jpeg"
            }
            alt="Picture of food on a table"
          />
        )}
        <div className="absolute z-40 md:transform md:scale-150">
          <NewLogo type="white" className="w-32 h-auto" />
        </div>
        {displayStoreButtons && (
          <BrowserView>
            <div className="hidden md:block absolute z-40 left-1/2 bottom-6 -ml-40">
              <div className="w-80">
                <div className="flex justify-center h-10 md:h-12">
                  <a
                    href={`https://apps.apple.com/us/app/kanpla/${appStoreId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="block h-full"
                  >
                    <img
                      src={`/images/appstore-${currentLang}.svg`}
                      alt="hent i app store"
                      className="h-full"
                    />
                  </a>
                  {!hideGooglePlay && (
                    <a
                      href={`https://play.google.com/store/apps/details?id=dk.kanpla.${googlePlayId}`}
                      target="_blank"
                      rel="noreferrer"
                      className="block h-full mx-2"
                    >
                      <img
                        src="/images/google_play.png"
                        alt="hent i app store"
                        className="h-full"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </BrowserView>
        )}
      </div>
    </div>
  );
};

export default SidebarBranded;
