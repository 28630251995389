import { AnonymousPropsFlow, School } from "@kanpla/types";
import { db, useCollectionListener, validateSelectors } from "@kanpla/system";
import { SelectorsInput } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import SelectSchool from "../../forms/SelectSchool";
import LoginLink from "../LoginLink";
import PageWrapper from "../PageWrapper";

const Salesplace = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation", "modals"]);
  const { customBranding } = useContainer(AppContext);
  const [allSchools = []] = useCollectionListener<School>(
    db.collection("schools")
  );

  const schools = allSchools.filter((s) => !s.hidden && !s.deleted);

  const {
    schoolId,
    setSchoolId,
    selectors,
    setSelectors,
    isSchoolForChildren,
    goToNextScreen,
  } = useContainer(SignUpFlowContext);

  // Cleanup selectors when selecting schools
  useEffect(() => {
    if (!schoolId) return;
    setSelectors(Object);
  }, [schoolId]);

  const submit = () => {
    // 1. Check values
    if (!schoolId) {
      throw new Error(t("signup-flow:message.error.choose-school"));
    }

    // 2. Check selectors
    const targetSchool = schools.find((s) => s.id === schoolId);
    const isSelectorValid = validateSelectors(
      selectors,
      targetSchool?.selectors
    );

    if (!isSchoolForChildren && !isSelectorValid)
      throw new Error(t("modals:fill-fields"));

    if (isSelectorValid) goToNextScreen();
  };

  const targetSchool = schools.find((s) => s.id === schoolId);

  return (
    <PageWrapper
      title={t("signup-flow:find-point-of-sale")}
      buttonTitle={t("translation:continue")}
      illustration="/images/hero-signup-1.svg"
      extraContent={<LoginLink />}
      onButtonSubmit={submit}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="w-full">
        <SelectSchool
          schools={schools}
          school={targetSchool}
          setSchool={(newSchool) => {
            setSchoolId(newSchool.id);
          }}
          edit={false}
          company={!isEmpty(customBranding?.logo)}
        />
      </div>
      {schoolId && !isSchoolForChildren && (
        <div className="mt-4">
          <SelectorsInput
            selectors={selectors}
            setSelectors={setSelectors}
            school={targetSchool}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default Salesplace;
