import { AnonymousPropsFlow } from "@kanpla/types";
import { FormInput } from "@kanpla/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import PageWrapper from "../PageWrapper";

const Reference = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { child } = useContainer(SignUpFlowContext);
  const [newReference, setNewReference] = useState<string>("");

  const submit = async () => {
    await child.ref.update({
      defaultReference: newReference,
    });
  };

  return (
    <PageWrapper
      title={t("signup-flow:reference-number")}
      subtitle={t("signup-flow:insert-number-to-help")}
      buttonTitle={t("signup-flow:add-reference-number")}
      topIllustration="/images/signup-flow/reference.svg"
      onButtonSubmit={submit}
      skipButton={true}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="w-full h-full mt-20">
        <FormInput
          label={t("translation:form.labels.default-reference")}
          note={t("translation:form.notes.default-reference-note")}
          value={newReference}
          setValue={(v) => setNewReference(v)}
        />
      </div>
    </PageWrapper>
  );
};

export default Reference;
