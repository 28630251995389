import { callFunction, KanplaError } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { InputPassword, SelectorsInput } from "@kanpla/ui";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";
import NameInput from "../partials/NameInput";
import { Checkbox, Form } from "antd";
import { isEmpty } from "lodash";
interface Data {
  password: string;
  newsletter?: boolean;
}

const Register = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["translation", "register"]);
  const {
    school,
    email,
    schoolId,
    name,
    setCurrentPage,
    multiSchoolsEmail,
    selectors,
    setSelectors,
  } = useContainer(SignUpFlowContext);
  const { customBranding, auth, isChildSalesplace } = useContainer(AppContext);
  const [accepted, setAccepted] = useState(false);
  const { isSchoolForChildren, throughInvitation, scope } =
    useContainer(SignUpFlowContext);
  const [data, setData] = useState<Data>({
    password: "",
    newsletter: true,
  });

  const showNewsletter = !customBranding?.name;

  const targetSchoolSelectors = multiSchoolsEmail?.find(
    (s) => s.schoolId === schoolId
  )?.selectors;

  const hasOnlyOneOption = targetSchoolSelectors?.[0]?.options?.length === 1;

  const shouldChooseSelectors =
    targetSchoolSelectors &&
    !isEmpty(targetSchoolSelectors) &&
    !hasOnlyOneOption;

  useEffect(() => {
    if (!hasOnlyOneOption) return;
    if (isEmpty(targetSchoolSelectors)) return;

    setSelectors(
      targetSchoolSelectors?.reduce((acc, sel) => {
        acc[sel.name] = sel.options?.[0]?.name;
        return acc;
      }, {})
    );
  }, [hasOnlyOneOption]);

  const submit = async () => {
    try {
      const { password, newsletter } = data;

      if (shouldChooseSelectors && isEmpty(selectors))
        throw new Error(t("register:message.error.choose-selector"));

      if (password.length < 6)
        throw new KanplaError(
          "auth/weak-password",
          "Use password with minimum 6 characters"
        );

      const { token } = await callFunction("signup-createAccount", {
        email,
        password,
        name,
        newsletter,
        partnerId: school?.partnerId,
        child: {
          name,
          schoolId,
          selectors,
          scope,
        },
        isChildSalesplace,
      });

      await auth.signInWithToken(token);
    } catch (err) {
      if (err.code === "already-exists") {
        setCurrentPage("accountAlreadyExists");
      } else {
        throw new Error(err);
      }
    }
  };

  return (
    <PageWrapper
      title={t("register:almost-there")}
      subtitle={
        <Trans t={t} i18nKey="register:affiliated">
          Du er tilknyttet til
          <span className="font-semibold ml-1">{{ value: school?.name }}</span>
          ✅
        </Trans>
      }
      buttonTitle={t("translation:sign-up-long")}
      htmlType="submit"
      onButtonSubmit={submit}
      showBackButton={true}
      disableButton={
        !accepted ||
        data?.password?.length < 6 ||
        (shouldChooseSelectors && isEmpty(selectors))
      }
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      {!isSchoolForChildren && <NameInput label={t("register:your-name")} />}

      <InputPassword
        value={data?.password || ""}
        onChange={(newValue) => setData({ ...data, password: newValue })}
        disableAutocomplete={true}
        defaultVisible={true}
      />

      {shouldChooseSelectors && (
        <div className="mb-4">
          <SelectorsInput
            selectors={selectors}
            setSelectors={setSelectors}
            useExtraSelectors
            extraSelectors={targetSchoolSelectors}
            school={school}
          />
        </div>
      )}

      <label className="text-text-secondary mb-2 text-left flex items-baseline">
        <Form.Item
          rules={[
            {
              required: true,
              message: t("register:do-accept-terms-and-conditions"),
            },
          ]}
        >
          <Checkbox
            id="signupLegalConsent"
            onChange={(v) => setAccepted(v.target.checked)}
            checked={accepted}
          />
        </Form.Item>
        <span className="text-sm leading-relaxed ml-3">
          <Trans t={t} i18nKey="register:terms-and-conditions">
            Jeg accepterer
            <a
              href="/kanpla_handelsvilkar.pdf"
              className="underline"
              target="_blank"
            >
              Handelsvilkårene
            </a>
            og
            <a
              href="/kanpla_persondatapolitik.pdf"
              className="underline"
              target="_blank"
            >
              Persondatapolitikken
            </a>
          </Trans>
        </span>
      </label>
      {showNewsletter && !throughInvitation && (
        <label className="text-text-secondary mb-2 text-left flex items-baseline">
          <Form.Item>
            <Checkbox
              id="signupNewsletter"
              onChange={(v) =>
                setData({ ...data, newsletter: !data?.newsletter ?? true })
              }
              checked={data?.newsletter ?? true}
            />
          </Form.Item>
          <span className="text-sm leading-relaxed ml-3">
            {t("register:subscribe")}
          </span>
        </label>
      )}
    </PageWrapper>
  );
};

export default Register;
